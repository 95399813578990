@use "sass:map";

.fab {
	&#{&} {
		// Specificity -_-v
		position: fixed;
		inset-block-end: em(64);
		inset-inline-end: var(--right, 1.6em);
		z-index: map.get($z-indexes, 'floating-action-buttons');
		block-size: rem(56);
		inline-size: rem(56);
		border-radius: 50%;
		padding: 0.5em;
		box-shadow: var(--shadow-lifted);
		transition: inset-block-end 0.5s easing(ease-out-quart);

		&--inBackground {
			z-index: 0;
		}

		@include media('>small') {
			inset-block-end: 1em;
		}
	}
}
