@use 'sass:math';

$treeview-border-width: 1px !default;
$treeview-indentation-margin: rem(30) !default;

.treeview-item {
	border-block-end: $treeview-border-width solid var(--border-norm);
	margin-block-end: -$treeview-border-width;

	$arrow-width: rem(9) !default;
	$arrow-height: rem(7) !default;
	$circle-size: rem(8) !default;

	&--move-inside,
	&--move-top,
	&--move-bottom {
		&::before {
			content: '';
			position: absolute;
		}
	}

	&--move-inside {
		background-color: var(--background-weak);

		&::before {
			inset-inline-start: -$arrow-width;
			block-size: 0;
			inline-size: 0;
			border: $arrow-height solid transparent;
			border-inline-start-color: var(--interaction-norm);
			border-inline-start-width: $arrow-width;
		}
	}

	&--move-top,
	&--move-bottom {
		&::before {
			inset-inline-start: -#{math.div($circle-size, 2)};
			block-size: $circle-size;
			inline-size: $circle-size;
			border-radius: 50%;
			background: var(--interaction-norm);
		}
	}

	&--move-inside#{&}--self-grabbed {
		// no primary color on the self grabbed element
		&::before {
			border-inline-start-color: var(--border-norm);
		}
	}

	&--move-top::before {
		inset-block-start: -#{math.div($circle-size, 2)};
	}

	&--move-bottom::before {
		inset-block-end: -#{math.div($circle-size, 2)};
	}

	&--move-top::after,
	&--move-bottom::after {
		content: '';
		position: absolute;
		inset-inline: 0;
		block-size: 1px;
		background: var(--interaction-norm);
	}

	&--move-top::after {
		inset-block-start: 0;
	}

	&--move-bottom::after {
		inset-block-end: -1px;
	}

	.treeview-item .treeview-item {
		padding-inline-start: $treeview-indentation-margin;

		&.treeview-item--move-inside {
			background: linear-gradient(
				to right,
				transparent 0,
				transparent #{$treeview-indentation-margin},
				var(--background-weak) #{$treeview-indentation-margin}
			);

			&::before {
				inset-inline-start: calc(-#{$arrow-width} + #{$treeview-indentation-margin});
			}
		}

		&.treeview-item--move-top,
		&.treeview-item--move-bottom {
			&::before {
				inset-inline-start: calc(-#{math.div($circle-size, 2)} + #{$treeview-indentation-margin});
			}
		}

		&--move-top::after,
		&--move-bottom::after {
			inset-inline-start: #{$treeview-indentation-margin};
		}
	}
}
