.simple-table {
	@extend .border-collapse;
	@extend .border-none;

	inline-size: 100%;

	--table-border-color: var(--border-norm);

	&-wrapper {
		inline-size: 100%;
		container-type: inline-size;
	}

	&--border-weak {
		--table-border-color: var(--border-weak);
	}

	caption {
		transform: scale(0);
	}

	td,
	th {
		padding: var(--space-2);
		border-block-end: 1px solid var(--table-border-color);
	}

	// NEW RESPONSIVE TABLES
	&--responsive {
		@container (max-width: 35rem) {
			td {
				display: block;
				margin: 0;
				padding: 0;
				border: none;
			}

			thead {
				display: none;
			}

			tr {
				display: block;
			}
		}
	}

	&--responsive-stacked {
		@container (max-width: 35rem) {
			tr {
				margin-block-end: var(--space-2);
				border-block-end: 1px solid var(--table-border-color);
			}

			td {
				padding-block-end: var(--space-2);
			}
		}
	}

	&--responsive-cards {
		@container (max-width: 35rem) {
			tr {
				padding: var(--space-3);
				border: 1px solid var(--table-border-color);
				border-radius: var(--border-radius-lg);

				&:not(:last-child) {
					margin-block-end: var(--space-3);
				}
			}

			td {
				&:not(:last-child) {
					padding-block-end: var(--space-2);
				}
			}
		}
	}

	&--responsive &__th-small {
		display: none;

		@container (max-width: 35rem) {
			display: flex;
			align-content: center;
			font-weight: var(--font-weight-medium);
			color: var(--text-weak);
			font-size: 0.86em;
			margin: 0;
		}
	}

	// END

	th:not(#{&}-row-th) {
		text-align: start;

		&.text-center {
			text-align: center;
		}

		&.text-right {
			text-align: end;
		}
	}

	// no style for this case
	&-row-th {
		font-weight: var(--font-weight-normal);
	}

	/* alternate bg for rows */
	&--alternate-bg-row tbody tr:nth-child(odd) {
		background-color: var(--background-weak);
	}

	&--is-hoverable tbody tr {
		@media (hover: hover) {
			&:hover,
			&:focus-within {
				background-color: var(--interaction-default-hover);
			}
		}
	}

	tr#{&}-sticky-row {
		td,
		th {
			position: sticky;
			inset-block-start: 0;
			background: var(--background-norm);
			z-index: 1;

			&::after {
				content: '';
				position: absolute;
				inset-block-start: 100%;
				inset-inline: 0;
				z-index: -1;
				block-size: rem(10);
				background: linear-gradient(to bottom, rgb(0 0 0 / var(--shadow-norm-opacity)) 0%, transparent 100%);
				pointer-events: none;
				transition: opacity 0.125s ease-out;
			}
		}
	}

	tr#{&}-sticky-row#{&}-sticky-row--is-on-top {
		td,
		th {
			&::after {
				opacity: 0;
			}
		}
	}

	/* border-table */
	&--border {
		&,
		th,
		td {
			border: 1px solid var(--table-border-color);
		}
	}

	&--has-actions {
		&#{&} {
			// Specificity -_-v
			td,
			th {
				&:last-child {
					text-align: end;
				}
			}
		}
	}

	// TODO: refactor this
	@container (max-width: 35rem) {
		&--responsive#{&}--has-actions {
			td,
			th {
				&:last-child {
					text-align: start;
				}
			}
		}
	}
}

.alternate-table-bg-row-rounded {
	@extend .border-none;

	inline-size: 100%;
	border-spacing: 0; // no border-collapse for radius ^^

	td,
	th {
		padding: var(--space-2);
	}

	/* alternate bg for rows */
	tbody tr:nth-child(odd) {
		// bg on all cells
		> * {
			background-color: var(--background-weak);
		}

		// radiuses
		> :first-child {
			border-start-start-radius: var(--border-radius-lg);
			border-end-start-radius: var(--border-radius-lg);
		}

		> :last-child {
			border-start-end-radius: var(--border-radius-lg);
			border-end-end-radius: var(--border-radius-lg);
		}
	}
}
