/**
 * Generate each transform origin possibilities
 * @return {String} - CSS classes for each transform origin value
 */
@mixin transform-origins() {
	@each $position,
		$origin
			in (
				(top, bottom center),
				(top-start, bottom left),
				(top-end, bottom right),
				(bottom, top center),
				(bottom-start, top left),
				(bottom-end, top right),
				(left, right center),
				(left-end, right bottom),
				(left-start, right top),
				(right, left center),
				(right-end, left bottom),
				(right-start, left top)
			)
	{
		&--#{$position} {
			transform-origin: $origin;
		}
	}
}
