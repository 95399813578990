@use 'sass:map';

@include responsive-classes(
	(
		static: (
			position: static,
		),

		relative: (
			position: relative,
		),

		absolute: (
			position: absolute,
			z-index: 1,
		),

		sticky: (
			position: sticky,
			z-index: 1,
		),

		fixed: (
			position: fixed,
		),
	)
);

.inset-0 {
	inset: 0;
}

.inset-center {
	inset-block-start: 50%;
	inset-inline-start: 50%;
	transform: translate(-50%, -50%);

	[dir='rtl'] & {
		transform: translate(50%, -50%);
	}
}

.inset-x-center {
	inset-inline-start: 50%;
	transform: translate(-50%, 0%);

	[dir='rtl'] & {
		transform: translate(50%, 0%);
	}
}

.inset-y-center {
	inset-block-start: 50%;
	transform: translateY(-50%);
}

.top-0 {
	inset-block-start: 0;
}

.bottom-0 {
	inset-block-end: 0;
}

.left-0,
.start-0 {
	inset-inline-start: 0;
}

.right-0,
.end-0 {
	inset-inline-end: 0;
}

///
/// set up to use inline style with custom props
/// to avoid having logical props in JS, postCSS can't process them
///
.top-custom {
	inset-block-start: var(--top-custom);
}

.bottom-custom {
	inset-block-end: var(--bottom-custom);
}

.left-custom {
	inset-inline-start: var(--left-custom);
}

.start-custom {
	inset-inline-start: var(--start-custom);
}

.right-custom {
	inset-inline-end: var(--right-custom);
}

.end-custom {
	inset-inline-end: var(--end-custom);
}

.inset-custom {
	inset: var(--inset-custom);
}

/// ---

.z-0 {
	z-index: 0;
}

.z-1 {
	z-index: 1;
}

@each $layer, $index in $z-indexes {
	.z-#{$layer} {
		z-index: $index;
	}
}

.z-custom {
	z-index: var(--z-custom);
}
