@import '~@proton/styles/scss/lib';

.price--large {
	.amount,
	.currency {
		font-size: em(48);
		line-height: 1.2; // font only takes the place for characters ^^
	}

	.suffix {
		color: var(--text-weak);
	}
}

// need to adapt fonts for subscription modal... not enough place
.subscription-modal {
	.price--large {
		.amount,
		.currency {
			@include media('<=#{em(1050, 16)}') {
				font-size: em(36); // not enough space
			}
			@include media('<=medium') {
				font-size: em(48);
			}
			@include media('<=#{em(800, 16)}') {
				font-size: em(36); // not enough space
			}
			@include media('<=small') {
				font-size: em(48);
			}
			@include media('<=xsmall') {
				font-size: em(36); // not enough space
			}
		}
	}
}
