.app-infos {
	&-fake-meter {
		block-size: em(4);
	}

	&-storage,
	&-version {
		color: var(--text-weak);
	}

	a#{&}-storage,
	&-version[type='button'] {
		&:hover,
		&:focus {
			color: var(--text-norm);
		}
	}

	&-compact &-name {
		@extend .sr-only;
	}
}
