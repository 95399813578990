@use 'sass:string';
@use 'sass:map';

$queries: (
	default: 'all',
	sm: '>xsmall',
	md: '>small',
	lg: '>medium',
	xl: '>large',
);

/// Generate a class stack selector form a class list and responsive prefix
/// @param {List} $classnames - List of classes for generation
/// @param {String} $prefix - Prefix from $queries
/// @return {String} Stacked classes with responsive prefixes

@function stack-classenames($classnames, $prefix) {
	$classstack: '';
	@each $classname in $classnames {
		// Remove dot (.) if there is one
		$classname: if(string.slice($classname, 1, 1) == '.', string.slice($classname, 2, -1), $classname);

		// Stack classe with dot, comma and responsive prefix
		$classstack: if($classstack == '', '.' + $prefix + $classname, $classstack + ', .' + $prefix + $classname);
	}
	@return $classstack;
}

/// Generate responsive classes, duh!
/// @param {String | List | Node} $param - List of classes
/// @content The declaration block of a css rule. Used when $param is a String or a List
/// @returns {String} CSS classes with responsive prefixes
/// @example scss - Basic usage: __String__
/// 	@include responsive-classes(dummy-class) {
/// 		property: value;
/// 	}
/// @example scss - Several classes with the same declaration block: __List__
/// 	@include responsive-classes((class-1, class-2)) {
/// 		property: value;
/// 	}
/// @example scss - When you need to serialize several classes to avoid cascade ordering issues: __Map__
/// 	@include responsive-classes((
/// 		class: (
/// 			property: value,
/// 		),
/// 		(class-1, class-2): (
/// 			property: value,
///			),
/// 	));

@mixin responsive-classes($param) {
	@each $breakpointname, $query in $queries {
		@include media($query) {
			$prefix: if($breakpointname == 'default', '', $breakpointname + '\\\:');
			@if type-of($param) == 'map' {
				@each $classnames, $properties in $param {
					#{stack-classenames($classnames, $prefix)} {
						@each $property, $value in $properties {
							// Search in the $value string if we need the breakpoint name, e.g. for custom sizing css variables
							$value: if(
								string.index(#{$value}, '[BPN]'),
								str-replace($value, '[BPN]', if($breakpointname == 'default', '', $breakpointname + '-')),
								$value
							);

							#{$property}: #{$value};
						}
					}
				}
			} @else {
				#{stack-classenames($param, $prefix)} {
					@content;
				}
			}
		}
	}
}
