.collapsing-breadcrumbs {
	display: flex;
	min-block-size: 2.5em;
	padding-block: var(--space-1);
	padding-inline-start: var(--space-0);
	margin: var(--space-0);
	flex-wrap: nowrap;
	align-items: center;
}

.collapsing-breadcrumb {
	display: flex;
	flex: 0 1 auto;
	flex-wrap: nowrap;

	&:not(#{&}--no-shrink) {
		min-inline-size: 2em;
	}
}

.collapsed-breadcrumb-active button {
	background-color: var(--button-hover-background-color);
	color: var(--button-hover-text-color);
}
