.step-label-v2 {
	z-index: 1;

	&::after {
		content: '';
		position: absolute;
		inset: 0;
		z-index: -1;
		border-radius: inherit;
		background-color: var(--primary);
		opacity: 0.3;
	}
}
