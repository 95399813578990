.checkbox {
	&-container {
		// position: relative; exported inside the component to enable increase-surface-click
		display: inline-flex;
	}

	&-input {
		&#{&} {
			// Specificity fail for width
			position: absolute;
			cursor: pointer;
			inset: 0;
			inline-size: 100%;
			block-size: 100%;
			z-index: 1;
			opacity: 0;
			margin: 0;
		}
	}

	&-fakecheck {
		display: inline-flex;
		min-inline-size: rem(20);
		inline-size: rem(20);
		block-size: rem(20);
		margin-block: auto;
		border-radius: var(--border-radius-sm);
		border: 1px solid var(--field-norm);
		background-color: var(--field-background-color);
		color: var(--field-text-color);
		transition: 0.15s easing(ease-out-cubic);

		&-img {
			margin: auto;
			transform: scale(0);
			transition: 0.15s transform easing(ease-out-back);
		}
	}

	&-input:hover + &-fakecheck {
		border-color: var(--interaction-norm);
		background-color: var(--field-hover-background-color);
		color: var(--field-hover-text-color);
	}

	@supports not selector(:focus-visible) {
		&-input:focus + &-fakecheck {
			border-color: var(--interaction-norm);
			box-shadow: 0 0 0 #{$focus-ring-size} var(--focus-ring);
			background-color: var(--field-focus-background-color);
			color: var(--field-focus-text-color);
		}

		&-input:focus:hover + &-fakecheck {
			border-color: var(--interaction-norm-hover);
		}
	}

	@supports selector(:focus-visible) {
		&-input:focus-visible + &-fakecheck {
			border-color: var(--interaction-norm);
			box-shadow: 0 0 0 #{$focus-ring-size} var(--focus-ring);
			background-color: var(--field-focus-background-color);
			color: var(--field-focus-text-color);
		}

		&-input:focus-visible:hover + &-fakecheck {
			border-color: var(--interaction-norm-hover);
		}
	}

	&-input[aria-invalid='true'] + &-fakecheck {
		border-color: var(--signal-danger);
		background-color: var(--field-background-color);
		color: var(--signal-danger);
	}

	&-input[disabled] + &-fakecheck {
		border-color: var(--field-disabled);
		background-color: var(--field-disabled-background-color);
		color: var(--field-disabled-text-color);
	}

	/*
	 * Checked state
	 */
	&-input:checked + &-fakecheck {
		border-color: var(--interaction-norm);
		background-color: var(--interaction-norm);
		color: var(--interaction-norm-contrast);
	}

	&-input:checked:hover + &-fakecheck {
		border-color: var(--interaction-norm-hover);
		background-color: var(--interaction-norm-hover);
		color: var(--interaction-norm-contrast);
	}

	@supports not selector(:focus-visible) {
		&-input:checked:focus + &-fakecheck {
			border-color: var(--interaction-norm);
			box-shadow: 0 0 0 #{$focus-ring-size} var(--focus-ring);
			background-color: var(--interaction-norm);
			color: var(--interaction-norm-contrast);
		}

		&-input:checked:focus:hover + &-fakecheck {
			border-color: var(--interaction-norm-hover);
		}
	}

	@supports selector(:focus-visible) {
		&-input:checked:focus-visible + &-fakecheck {
			border-color: var(--interaction-norm);
			box-shadow: 0 0 0 #{$focus-ring-size} var(--focus-ring);
			background-color: var(--interaction-norm);
			color: var(--interaction-norm-contrast);
		}

		&-input:checked:focus-visible:hover + &-fakecheck {
			border-color: var(--interaction-norm-hover);
			background-color: var(--interaction-norm-hover);
		}
	}

	&-input:checked[aria-invalid='true'] + &-fakecheck {
		border-color: var(--signal-danger);
		background-color: var(--signal-danger);
		color: var(--signal-danger-contrast);
	}

	&-input:checked[disabled] + &-fakecheck,
	&-input:checked[disabled]:indeterminate + &-fakecheck {
		border-color: var(--field-disabled);
		background-color: var(--field-disabled);
		color: var(--interaction-norm-contrast);
	}

	&-input:checked:indeterminate + &-fakecheck {
		border-color: var(--field-norm);
		background-color: var(--field-background-color);
		color: var(--field-text-color);
	}

	&-input:checked + &-fakecheck &-fakecheck-img,
	&-input:indeterminate + &-fakecheck &-fakecheck-img {
		transform: scale(1);
		transition-delay: 0.1s;
	}
}
