@use 'sass:map';
@import '~@proton/styles/scss/lib';

.card-information {
	.exp,
	.cvv {
		flex: initial;
	}

	.exp {
		.input-element {
			// ems should scale accordingly on mobile.
			max-inline-size: 5.5em;
		}
	}

	.cvv {
		.input-element {
			// ems should scale accordingly on mobile.
			max-inline-size: 3em;
		}
	}

	.input-adornment {
		margin-inline: 0;

		.input-element {
			border-radius: 0;
		}
	}
}

// for larger version
.field-two--bigger {
	.exp:not(.exp--small),
	.cvv:not(.cvv--small) {
		.input-element {
			padding-block: rem(6);
		}
	}
}

.country-select {
	.input-adornment {
		color: var(--text-norm);
		margin-inline: 0;

		.input-element {
			border-radius: 0;
			padding-inline-start: rem(12) !important;
		}

		// we want the country dropdown to take exactly half of the width
		&:first-child {
			flex: 0 0 50%;
		}

		&:last-child {
			border: none !important;
		}
	}
}

.error-container {
	color: var(--signal-danger);
	min-block-size: rem(20);
}

.card-number--small,
.exp--small,
.cvv--small {
	&.error {
		z-index: map.get($z-indexes, 'up');
	}

	&:focus-within {
		// we want the focus frame to be displayed over error frame
		z-index: map.get($z-indexes, 'up') + 1;
	}
}

.card-number--small {
	&#{&} {
		border-radius: var(--border-radius-md) var(--border-radius-md) 0 0;
		margin-block-end: -1px;
	}
}

.exp--small {
	&#{&} {
		border-radius: 0 0 0 var(--border-radius-md);
	}
}

.cvv--small {
	&#{&} {
		border-radius: 0 0 var(--border-radius-md) 0;
		margin-inline-start: -1px;
	}
}
