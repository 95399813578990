// star button
.starbutton {
	/*
	 * We need to override the color, no matter the specificity of the button
	 * so we can use `!important` in this situation.
	 * no focus state, only focus-visible
	 */
	&:focus-visible,
	&:hover,
	&--is-starred {
		color: var(--favorite-icon-color) !important;
	}
}
