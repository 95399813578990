@import '~@proton/styles/scss/lib';

.bg-norm-weak {
	background-color: #efecfd;
}

.signup-v1-bg {
	@include media('>xsmall') {
		background: linear-gradient(68.66deg, #e8e2ff 1.3%, #f7f5ff 50%);
	}
}

.signup-v1-bg--dark {
	@include media('>xsmall') {
		background: linear-gradient(359.26deg, #1b1340 29.47%, #392982 114.26%);

		--optional-promotion-text-color: white;
		--text-norm: white;
	}

	.vpn-for-business-logo {
		color: var(--optional-promotion-text-color);
		@include media('<xsmall') {
			display: none;
		}
	}

	.proton-for-business-logo {
		color: var(--optional-promotion-text-color);
		@include media('>xsmall') {
			display: none;
		}
	}
}

.signup-v1-bg--bf2023 {
	@include media('>xsmall') {
		background: radial-gradient(
				45.16% 45.16% at 50% -4.08%,
				rgb(0 240 195 / 0.3) 0%,
				rgb(74 136 255 / 0.2) 58.39%,
				rgb(109 74 255 / 0) 100%
			),
			linear-gradient(0deg, #191927, #191927);

		--optional-promotion-text-color: white;
		--text-norm: white;
	}

	.vpn-logo {
		&--light {
			color: var(--optional-promotion-text-color);

			--logo-text-proton-color: white;
			--logo-text-product-color: white;
		}

		@include media('<xsmall') {
			display: none;
		}
	}
}

.signup-v1-header {
	max-inline-size: rem(960);

	.large-font {
		font-size: rem(32);
		color: var(--optional-promotion-text-color);

		@include media('<=small') {
			font-size: rem(24);
		}
	}
}
