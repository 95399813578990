/* label */
// $label-width defined in global vars
.label {
	padding-block-start: 0.5em;
	inline-size: var(--label-width, $label-width);
	padding-inline-end: 0.5em;
	max-inline-size: 100%;
	flex-shrink: 0;

	&.pt-0 {
		padding-block-start: 0;
	}

	&.auto {
		inline-size: auto;
	}

	&--small {
		font-size: rem(12);
		line-height: 2;
	}
}

// case for forms labelled only with icons (calendar popover, etc.)
.form--icon-labels .label {
	--label-width: 2.4em;

	@include media('<=xsmall') {
		--label-width: 1.75em;
	}
}
