.autocomplete-suggestions {
	position: absolute;
	z-index: -1;
	inset-block-start: calc(100% - 2px);
	inset-inline: 0.35em;
	min-block-size: 80%;
	max-block-size: calc(5 * 2.3em);
	overflow: scroll;
	background-color: var(--background-norm);
	box-shadow: var(--shadow-lifted);
	color: var(--text-norm);
	border: 1px solid var(--border-norm);
	transform: translateY(-1.5em);
	opacity: 0;
	transition: transform 100ms ease-in, opacity 100ms ease-out 20ms;

	&,
	div:last-child {
		border-end-start-radius: var(--border-radius-md);
		border-end-end-radius: var(--border-radius-md);
	}

	div {
		@extend .interactive-pseudo;

		&.selected {
			background-color: var(--interaction-norm);
			color: var(--interaction-norm-contrast);
			pointer-events: none;
		}
	}

	.button.button-link {
		color: inherit;
	}

	&--open {
		transform: translateY(0);
		opacity: 1;
	}
}

.autocomplete-input {
	z-index: 1;
}
