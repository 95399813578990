@import '~@proton/styles/scss/lib';

$width-hv-modal: 730 !default;

.human-verification-modal {
	&#{&} {
		// Specificity -_-v
		// Just tested this value, don't change it
		inline-size: rem($width-hv-modal);
		max-inline-size: rem($width-hv-modal);

		// below this value, we get back to normal modal
		@include media('<=#{em($width-hv-modal, 16)}') {
			max-inline-size: 98%;
		}
	}
}
