// .shadow-norm .shadow-lifted for purgecss

@each $type in (norm, raised, lifted) {
	:root {
		--shadow-#{$type}: var(--shadow-#{$type}-offset)
			rgb(var(--shadow-color, var(--shadow-default-color)) / var(--shadow-#{$type}-opacity));
	}
}

@include responsive-classes(
	(
		shadow-norm: (
			box-shadow: var(--shadow-norm-offset)
				rgb(var(--shadow-color, var(--shadow-default-color)) / var(--shadow-norm-opacity)),
		),

		shadow-raised: (
			box-shadow: var(--shadow-raised-offset)
				rgb(var(--shadow-color, var(--shadow-default-color)) / var(--shadow-raised-opacity)),
		),

		shadow-lifted: (
			box-shadow: var(--shadow-lifted-offset)
				rgb(var(--shadow-color, var(--shadow-default-color)) / var(--shadow-lifted-opacity)),
		),

		shadow-none: (
			box-shadow: none,
		),
	)
);

.shadow-color-norm {
	--shadow-color: var(--shadow-default-color);
}

.shadow-color-primary {
	--shadow-color: var(--shadow-primary-color);
}
