@use 'sass:math';
@use 'im-to-em' as *;

$base-font-size: 14 !default; // if "14" then 1em = 14px
$base-line-height: math.div(20, $base-font-size) !default; // Design specs says 20px

$title-sizes: (
	h1: 36,
	h2: 28,
	h3: 20,
	h4: 18,
	h5: 17,
	h6: 16,
) !default;

// Use typo helpers on demand
$use-other-typo-helpers: true !default;
$use-other-line-height-helpers: true !default;

// fonts
/* stylelint-disable-next-line value-keyword-case */
$font-family-monospace: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New',
	monospace !default;

// Breakpoints for include-media
$breakpoints: im-to-em(
	(
		'xlarge': 1500px,
		'large': 1100px,
		'medium': 910px,
		'small': 680px,
		'xsmall': 450px,
	)
) !default;

// specific breakpoints
$breakpoint-for-drawer: 1400px;

// Media expressions for include-media
$media-expressions: (
	'all': 'all',
	'screen': 'screen',
	'print': 'print',
	'mouse': '(hover: hover) and (pointer: fine)',
	'touch': '(hover: hover) and (pointer: coarse), (hover: none)',
	'landscape': '(orientation: landscape)',
	'portrait': '(orientation: portrait)',
	'reduced-motion': '(prefers-reduced-motion: reduce)',
) !default;

// RTL
// RTL = Right To Left =>
// to adapt a website in a language that is written from right to left
// designed for multilingual websites with LTR et RTL
$rtl-option: true !default;

// Custom scrollbar everywhere
$custom-scroll: true !default;

// hyphens
$hyphenation: false !default;

// clear floats
$use-clearleft-right: false !default;
$use-clearhidden: false !default;
$use-clearfix: false !default;

// basic premise: default user setting for font size is at 16 pixels in the browser
// you should NEVER update this value, however… who can predict the future…
$root-default-font-size: 16 !default;

// others
$use-reset-button: true !default;
$use-vertical-align-img-iframe: true !default;
$use-ie-calc-font-fallback: false !default;
$use-old-ie-fallbacks: true !default;
$use-height-auto-img: true !default;
$use-height-auto-td: true !default;
$use-height-auto-table: true !default;

// default config for webpack
$path-images: '~@proton/styles/assets/img/illustrations/' !default;

// variables for global layouts, allowing you to use calc for components and adapt quickly if needed
$header-height: 60 !default;
$header-height-small: 44 !default;
$width-sidebar: 250 !default; // main navigation
$context-bar-width: 25vw !default; // context bar on the right
$context-bar-min-width: 300 !default;
$context-bar-max-width: 400 !default;
$default-height-fields: 36 !default;
/* stylelint-disable-next-line */
$focus-ring-size: 0.1875rem; // 3px

$conversations-column-width: 35% !default; // used also to align search bar on top

$label-width: 18em !default;
$conversation-icon-size: 32 !default;
$conversation-icon-size-small: 28 !default;

// Z-indexes for stacking management
$z-indexes: (
	'up': 10,
	'dropzone': 100,
	'floating-action-buttons': 200,
	'drawer': 300,
	'floating-frames': 400,
	'previewer': 500,
	'transfer-manager': 600,
	'spotlights': 700,
	// Can be overridden to display above modals
	'mobile-navigation': 800,
	'modals': 900,
	'notifications': 1000,
	'tooltips': 1100,
);
