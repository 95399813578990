.aside-link {
	border-radius: 50%;
	inline-size: rem(32);
	block-size: rem(32);
	padding: rem(7);
	background: var(--interaction-weak);
	color: var(--text-norm);

	&:hover,
	&:focus {
		background: var(--interaction-weak-hover);
		color: var(--primary);
	}

	&:active,
	&[aria-current='true'] {
		background: var(--interaction-weak-active);
		color: var(--primary);
	}

	&-icon {
		inline-size: rem(17);
		block-size: rem(17);
		fill: currentcolor;
	}
}
