.meter-bar {
	--size: #{em(10)};
	--meter-bar-background-color: var(--border-norm);
	--meter-bar-thumb-color-success: var(--signal-success);

	inline-size: 100%;
	overflow: hidden;
	background-color: var(--meter-bar-background-color);

	&,
	&-thumb {
		block-size: var(--size);
	}

	&-thumb {
		min-inline-size: 3%;
		background: var(--meter-bar-thumb-color-success);

		&--warning {
			background: var(--signal-warning);
		}

		&--danger {
			background: var(--signal-danger);
		}
	}

	&:not(.is-squared),
	&:not(.is-squared) &-thumb {
		border-radius: calc(var(--size) / 2);
	}

	&.is-thin {
		--size: #{em(4)};
	}
}
