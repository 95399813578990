@use 'sass:math';
@import '~@proton/styles/scss/lib';

$height-track: 16 !default;
$height-track-small: 8 !default;
$size-track-thumb: 36 !default;
$size-track-thumb-small: 28 !default;

.slider {
	/**
	 * Prevent scroll while interacting with slider on mobile.
	 * Support on iOS safari for this CSS property starts on version 13.
	 *
	 * TODO: may have to add support for iOS safari < 13 for this behaviour.
	 */
	touch-action: none;
	block-size: rem($height-track);

	/**
	 * Inline-block and 100% width prevents the bottom margin from collapsing in parent.
	 */
	inline-size: 100%;
	display: inline-block;

	&-rail,
	&-track,
	&-thumb {
		position: absolute;
	}

	&-rail {
		background: var(--background-strong);
		inline-size: 100%;
		block-size: 100%;

		/*
		 * The track is nested inside of the rail, so to make the
		 * border-radius apply to both the rail & the track we clip
		 * it with overflow: hidden.
		 */
		border-radius: rem(math.div($height-track, 2));
		overflow: hidden;
	}

	&-track {
		background: var(--track-background);
		block-size: 100%;
	}

	&-thumb {
		block-size: rem($size-track-thumb);
		inline-size: rem($size-track-thumb);
		transition: none;
		user-select: none;
		display: flex;
		align-items: center;
		justify-content: center;
		transform: translateX(-50%) translateY(-10px);

		[dir='rtl'] & {
			transform: translateX(50%) translateY(-10px);
		}

		&-input {
			inline-size: 100%;
			block-size: 100%;
		}
	}

	&-small {
		block-size: rem($height-track-small);

		.slider-thumb {
			block-size: rem($size-track-thumb-small);
			inline-size: rem($size-track-thumb-small);
			padding: 0;
		}

		.slider-rail {
			border-radius: rem(math.div($height-track-small, 2));
		}
	}

	&-marks {
		/**
		 * Combined height of slider mark (12) + slider mark label line height (20).
		 * If we don't provide a bottom margin anything added underneath the Slider
		 * collapses into the marks / labels.
		 */
		margin-block-end: rem(32);

		.slider-rail {
			/*
			 * No border-radius when marks are applied so that the marks nicely align
			 * with the corners of the non-rounded rail.
			 */
			border-radius: 0;
		}
	}
}
