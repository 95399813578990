@import '~@proton/styles/scss/lib';

:root {
	--scrollshadow-size: 0.313rem;
	--scrollshadow-opacity: var(--shadow-norm-opacity);
}

.scroll {
	&-outer {
		&-horizontal {
			position: relative;

			.scroll-child {
				/*
				 * the reason this is using inline-block is because
				 * we need direct access to a child element of the scroll
				 * container in order to know when the edges of these two
				 * elements overlap (that way we can tell that the scroll
				 * is either at the very start, at the very end or somewhere
				 * in the middle)
				 *
				 * however, a block element always takes the width of their
				 * parent, whereas an inline-block element will take the width
				 * of their child, which would be the overflowing scrollable
				 * content in this case
				 *
				 * "width: 100%" in order to maintain a block element behaviour
				 * even though using "display: inline-block"
				 *
				 */
				display: inline-block;
				min-inline-size: 100%;
			}

			.scroll-inner {
				overflow-x: auto;
			}

			.scroll-start-shadow,
			.scroll-end-shadow {
				z-index: 1;
				position: absolute;
				inset-block-start: 0;
				inline-size: var(--scrollshadow-size);
				block-size: 100%;
				opacity: 0;
				transition: 250ms easing(ease-out-cubic);
			}

			.scroll-start-shadow {
				inset-inline-start: 0;
				background: linear-gradient(90deg, rgb(0 0 0 / var(--scrollshadow-opacity)), transparent);
			}

			.scroll-end-shadow {
				inset-inline-end: 0;
				background: linear-gradient(270deg, rgb(0 0 0 / var(--scrollshadow-opacity)), transparent);
			}

			.scroll-start-shadow-visible,
			.scroll-end-shadow-visible {
				opacity: 1;
			}
		}

		&-vertical {
			block-size: 100%;
			position: relative;

			.scroll-inner {
				block-size: 100%;
				overflow-y: auto;

				&--contained {
					overscroll-behavior: contain;
				}
			}

			.scroll-start-shadow,
			.scroll-end-shadow {
				z-index: 1;
				position: absolute;
				inset-inline: 0 0;
				block-size: var(--scrollshadow-size);
				transition: 250ms easing(ease-out-cubic);
				opacity: 0;
			}

			.scroll-start-shadow {
				inset-block-start: 0;
				background: linear-gradient(180deg, rgb(0 0 0 / var(--scrollshadow-opacity)), transparent);
			}

			.scroll-end-shadow {
				inset-block-end: 0;
				background: linear-gradient(0deg, rgb(0 0 0 / var(--scrollshadow-opacity)), transparent);
			}

			.scroll-start-shadow-visible,
			.scroll-end-shadow-visible {
				opacity: 1;
			}
		}
	}
}
