.is-electron-mac {
	background: transparent;

	.content,
	.sidebar {
		@include media('>small') {
			background: transparent;
		}
	}

	.sidebar {
		--text-norm: rgb(0 0 0 / 0.85);
		--text-weak: rgb(0 0 0 / 0.65);
		--text-hint: rgb(0 0 0 / 0.55);
		--interaction-default: rgb(0 0 0 / 0);
		--interaction-default-hover: rgb(0 0 0 / 0.1);
		--interaction-default-active: rgb(0 0 0 / 0.15);
		--interaction-weak: rgb(0 0 0 / 0.1);
		--interaction-weak-hover: rgb(0 0 0 / 0.15);
		--interaction-weak-active: rgb(0 0 0 / 0.2);
		--border-norm: rgb(0 0 0 / 0.1);
		--border-weak: rgb(0 0 0 / 0.05);
		--promotion-background-start: rgb(109 74 255 / 0.25);
		--promotion-background-end: rgb(109 74 255 / 0.1);
	}

	.header {
		block-size: rem(56);
		padding-block: rem(10);
	}

	.is-in-background {
		.header,
		.drawer-sidebar,
		.sidebar {
			> * {
				filter: saturate(0.8) brightness(0.95);
				opacity: 0.8;
			}
		}
	}

	.header,
	.sidebar-header,
	.top-bar,
	.loader-page.h-full,
	.file-preview-header,
	.sign-layout-main-header {
		-webkit-app-region: drag;
		user-select: none;

		a,
		button,
		input,
		select,
		textarea,
		ol,
		ul {
			-webkit-app-region: no-drag;
			user-select: initial;
		}
	}

	&.is-drawer-app,
	.drawer-app-view,
	&:has(.eventpopover),
	&:has(#webpack-dev-server-client-overlay) {
		.header,
		.sidebar-header,
		.loader-page.h-full,
		.file-preview-header,
		.sign-layout-main-header {
			-webkit-app-region: no-drag;
			user-select: initial;
		}
	}

	dialog,
	[role='dialog'] {
		-webkit-app-region: no-drag;
		user-select: initial;
	}
}

.is-electron-light {
	&.is-electron-mac {
		background: rgb(255 255 255 / 0.55);

		@media (prefers-color-scheme: light) {
			background: transparent;
		}

		.main {
			box-shadow: 0 0 2px 0 #00000026;
		}

		.is-in-background {
			.header,
			.drawer-sidebar,
			.main {
				background: var(--background-weak);
			}
		}
	}
}

.is-electron-dark {
	&.is-electron-mac {
		background: rgb(5 0 13 / 0.45);

		@media (prefers-color-scheme: dark) {
			background: transparent;
		}

		.sidebar {
			--text-norm: rgb(255 255 255 / 0.9);
			--text-weak: rgb(255 255 255 / 0.6);
			--text-hint: rgb(255 255 255 / 0.5);
			--interaction-default: rgb(255 255 255 / 0);
			--interaction-default-hover: rgb(255 255 255 / 0.1);
			--interaction-default-active: rgb(255 255 255 / 0.15);
			--interaction-weak: rgb(255 255 255 / 0.1);
			--interaction-weak-hover: rgb(255 255 255 / 0.15);
			--interaction-weak-active: rgb(255 255 255 / 0.2);
			--border-norm: rgb(255 255 255 / 0.1);
			--border-weak: rgb(255 255 255 / 0.05);
			--promotion-background-start: rgb(132 102 255 / 0.3);
			--promotion-background-end: rgb(132 102 255 / 0.1);
		}
	}

	.sign-layout-bg {
		background: linear-gradient(62.88deg, #32226f 30%, #1d1738 100%);
		color: var(--text-norm);

		.signup-link {
			color: var(--text-norm);
		}
	}
}
