@use 'sass:math';

@if $custom-scroll {
	html:not(.feature-scrollbars-off) {
		* {
			@supports (scrollbar-width: auto) {
				scrollbar-width: thin;
				scrollbar-color: var(--scrollbar-thumb-color) transparent;
			}

			@supports selector(::-webkit-scrollbar) {
				$real-size: rem(6) + (rem(2) * 2);

				&::-webkit-scrollbar {
					inline-size: $real-size;
					block-size: $real-size;
				}

				&::-webkit-scrollbar-thumb {
					border: rem(2) solid transparent;
					background-clip: padding-box;
					border-radius: math.div($real-size, 2);
					background-color: var(--scrollbar-thumb-color);
				}

				&::-webkit-scrollbar-track {
					background-color: transparent;
				}

				&::-webkit-scrollbar-corner {
					visibility: hidden;
				}
			}
		}

		@include media('mouse') {
			body *:not(:hover, :focus, :focus-within) {
				@supports (scrollbar-width: auto) {
					scrollbar-color: transparent transparent;
				}

				@supports selector(::-webkit-scrollbar) {
					&::-webkit-scrollbar-thumb {
						visibility: hidden;
					}
				}
			}
		}
	}
}
