@import '~@proton/styles/scss/lib';

.stepper {
	$dot-size: em(12);
	$weak-opacity: 0.7;
	$connector-height: em(2);
	$connector-gap: em(2);
	$connector-max-width: em(120);

	inline-size: 100%;

	&-item {
		max-inline-size: calc($connector-max-width + $dot-size - $connector-gap);

		&-dot {
			inline-size: $dot-size;
			block-size: $dot-size;
			border-radius: 50%;
		}

		&-connector {
			block-size: $connector-height;
			position: absolute;
			inset-block-start: calc(($dot-size - $connector-height) / 2);
			inset-inline: calc(-50% + $connector-gap) calc(50% + $connector-gap + $dot-size / 2);
		}

		/**
		 * dot and connector default color
		 */
		&-dot,
		&-connector {
			background-color: var(--interaction-norm);
			opacity: $weak-opacity;
		}

		/**
		 * dot and connector active and completed
		 */
		&--active &-dot,
		&--completed &-dot,
		&--active &-connector,
		&--completed &-connector {
			opacity: 1;
		}
	}

	@include media('<=xsmall') {
		$connector-max-width: em(40); // smaller for mobile
		&-item {
			max-inline-size: calc($connector-max-width + $dot-size - $connector-gap);
		}

		&-label {
			visibility: hidden;

			&.stepper-item--active {
				visibility: visible;
				flex: 2 1 0px;
				min-block-size: 3em; // for signup, I need to have a predictible height (and it could avoid jumps btw)
				min-inline-size: 50%; // YOLO, enough space for text
			}
		}
	}
}
