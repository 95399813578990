@import '~@proton/styles/scss/lib';

.tabs {
	& > &-nav &-link {
		transition: 0.15s easing(ease-out-quint);

		&[disabled] {
			opacity: 0.4;
			cursor: default;
			pointer-events: none;
		}
	}

	& > &-nav &-list--fullWidth {
		inline-size: 100%;

		.tabs-list-item {
			flex: 1 1 0;
		}

		.tabs-list-link {
			inline-size: 100%;
		}
	}

	&--underline > &-nav &-list-item {
		min-inline-size: auto;
	}

	&--underline > &-nav &-list-link {
		padding-block: var(--space-2);
		padding-inline: var(--space-3);
		inset-block-start: 1px;
		border-block-end: 2px solid transparent;

		&:hover,
		&:focus {
			border-block-end-color: var(--border-norm);
		}

		&[aria-selected='true'] {
			border-block-end-color: var(--interaction-norm);
		}
	}

	&--underline > &-nav &-indicator {
		position: absolute;
		inset-block-end: -1px;
		inset-inline: 0;
		block-size: 0;
		transform-origin: left center;
		transform: translateX(var(--translate)) scaleX(var(--scale));
		border-block-end: 2px solid var(--interaction-norm);
		transition: transform 0.15s easing(ease-out-quad);

		@at-root {
			[dir='rtl'] & {
				transform-origin: right center;
			}
		}
	}

	&--modern > &-nav &-list {
		padding: var(--space-1);
		gap: var(--space-1);
		background-color: var(--background-lowered);
		border-radius: var(--border-radius-lg);
		z-index: 1;
	}

	&--modern > &-nav &-list-item {
		position: relative;

		&:hover {
			&::after {
				content: '';
				position: absolute;
				inset: 0;
				z-index: -1;
				border-radius: var(--border-radius-md);
				background-color: var(--interaction-default-hover);
			}
		}
	}

	&--modern > &-nav &-list-link {
		z-index: 3;
		padding-block: var(--space-1);
		padding-inline: var(--space-2);

		&[aria-selected='true'] {
			pointer-events: none;
		}

		> span {
			&::after {
				content: attr(data-title);
				visibility: hidden;
				display: inline-block;
				font-weight: var(--font-weight-semibold);
				block-size: 0;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}

	&--modern > &-nav &-indicator {
		position: absolute;
		inset-block: var(--space-1);
		inset-inline-start: 0;
		transform-origin: left center;
		transform: translateX(var(--translate));
		inline-size: var(--width);
		transition: transform 0.3s easing(ease-out-quad);
		border-radius: var(--border-radius-md);
		background-color: var(--background-elevated);
		box-shadow: var(--shadow-norm);
		z-index: 2;

		@at-root {
			[dir='rtl'] & {
				transform-origin: right center;
			}
		}
	}
}
