@use 'sass:math';

@import '~@proton/styles/scss/lib';

.circle-loader {
	$total-length: 43.699; // total length of path, calculated by getTotalLength() in JS
	$looping-percent: 60;
	$looping-length: math.div($looping-percent * $total-length, 100);

	display: inline-block;
	inline-size: 1em;
	block-size: 1em;
	transform-origin: 50%;
	font-size: 1em;
	vertical-align: middle;

	&-track,
	&-circle {
		fill: none;
		stroke-width: var(--stroke-width, 2);
		stroke-linecap: round;
		stroke: currentcolor;
	}

	&-track {
		opacity: 0.2;
	}

	&-circle {
		stroke-dasharray:
			#{$looping-length * 1px},
			#{($total-length - $looping-length) * 1px};
		animation: anime-loader-stroke 1s linear infinite;

		@keyframes anime-loader-stroke {
			from {
				stroke-dashoffset: $total-length;
			}

			to {
				stroke-dashoffset: 0;
			}
		}
	}

	/* Small is the default */
	&.is-tiny {
		font-size: em(10);
	}

	&.is-medium {
		--stroke-width: 1.3;

		font-size: em(32);
	}

	&.is-large {
		--stroke-width: 1;

		font-size: em(48);
	}
}
