.settings-loading-page-title,
.settings-loading-section-title,
.settings-loading-paragraph-line {
	&::before {
		@extend %item-loading-pseudo;
		@extend %placeholder-loading;
	}
}

.settings-loading-page-title {
	&::before {
		inline-size: 30%;
	}
}

.settings-loading-section-title {
	&::before {
		inline-size: 50%;
	}
}

.settings-loading-paragraph-line {
	&:nth-child(1) {
		&::before {
			inline-size: 90%;
		}
	}

	&:nth-child(2) {
		&::before {
			inline-size: 100%;
		}
	}

	&:nth-child(3) {
		&::before {
			inline-size: 40%;
		}
	}
}
