.breadcrumb {
	&-container {
		@extend .border;
	}

	&-button {
		padding: 0.4em;
		opacity: 0.3;
		color: inherit;

		&[disabled][aria-current='step'] {
			font-weight: var(--font-weight-bold);
			opacity: 1;
			color: inherit;
		}
	}
}
