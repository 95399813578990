.\*\:flex > * {
	display: flex;
}

@include responsive-classes(
	(
		block: (
			display: block,
		),

		inline: (
			display: inline,
		),

		inline-block: (
			display: inline-block,
		),

		flex: (
			display: flex,
			flex-wrap: wrap,
		),

		inline-flex: (
			display: inline-flex,
			flex-wrap: wrap,
		),

		grid: (
			display: grid,
		),

		hidden: (
			display: none,
		),

		table-cell: (
			display: table-cell,
		),
	)
);

.flex,
.sm\:flex,
.md\:flex,
.lg\:flex,
.xl\:flex {
	& > * {
		min-block-size: 0;
		min-inline-size: 0;
	}
}

// don't use this if you don't know what you're doing
.display-contents {
	display: contents;
}

/* table-design in CSS */
.row {
	display: table;
	table-layout: fixed;
}

.inline-row {
	display: inline-table;
	table-layout: fixed;
}

.line {
	display: table-row;
}

.col {
	display: table-cell;
	vertical-align: top;
}

.col-no-align {
	display: table-cell;
}

[hidden],
.empty\:hidden:empty {
	display: none;
}

// deprecated: hide-on-hover
.group-hover\:hidden {
	.group-hover-hide-container:focus-within &,
	.group-hover-hide-container:hover & {
		display: none;
	}
}
