// When adding new opacity classes, add the values also in Storybook
// applications/storybook/src/stories/cssUtilities/Opacity.stories.tsx

.opacity-70 {
	opacity: 0.7;
}

.opacity-65 {
	opacity: 0.65;
}

.opacity-50 {
	opacity: 0.5;
}

.opacity-40 {
	opacity: 0.4;
}

.opacity-30 {
	opacity: 0.3;
}

.opacity-0 {
	opacity: 0;
	pointer-events: none;
}

.focus\:opacity-100 {
	&:focus,
	&:focus-within {
		opacity: 1;
		pointer-events: all;
	}
}

// Make hover-only interaction focusable with keyboard
// deprecated: opacity-on-hover
$container-name: 'group-hover-opacity-container';

.group-hover\:opacity-100 {
	&:not([aria-expanded='true'], .is-active) {
		opacity: 0; // Do not use visibility to keep it focusable even when invisible
	}

	// Need a container to manage with focus-within
	@at-root {
		.#{$container-name}:focus-within &,
		.#{$container-name}:focus-within .mouse\:group-hover\:opacity-100 {
			opacity: 1;
		}
	}

	@include media('mouse') {
		.#{$container-name}:not(:hover, :focus, :focus-within) &-no-width:not([aria-expanded='true'], .is-active) {
			position: absolute;
			transition: none;
			pointer-events: none;
			visibility: hidden;
		}

		@at-root {
			.#{$container-name}:hover &,
			.#{$container-name}:hover .mouse\:group-hover\:opacity-100 {
				opacity: 1;
				visibility: visible;
			}
		}

		@at-root {
			&,
			.mouse\:group-hover\:opacity-100 {
				opacity: 0;
				will-change: opacity;
			}
		}
	}
}
