.ellipsis-loader {
	display: inline-block;
	inline-size: 1.3em;
	font-family: $font-family-monospace;
	letter-spacing: -0.2em;
	vertical-align: baseline;
	text-align: start;
	white-space: pre;
	pointer-events: none;

	&::before {
		content: ' ';
		animation: anime-ellipsis-loading 1s steps(1, start) infinite;
	}
}

@keyframes anime-ellipsis-loading {
	0% {
		content: ' ';
	}

	25% {
		content: '.';
	}

	50% {
		content: '..';
	}

	75% {
		content: '...';
	}
}
