.select-dropdown {
	&--togglable {
		.dropdown-item {
			[aria-current='true'],
			&--is-selected {
				&,
				&:hover,
				&:focus,
				&:active {
					pointer-events: all;
				}
			}
		}
	}
}
