@include media('print') {
	/**
	 * add in this all elements which need to be hardly reseted for print
	 */
	body,
	html,
	.page,
	.reset4print {
		background-color: white;
		background-image: none;
		border: 0;
		box-shadow: none;
		color: black;
		float: none;
		block-size: auto;
		margin: 0;
		max-inline-size: 100%;
		min-block-size: 0;
		padding: 0;
		position: static;
		inline-size: auto;
		opacity: 1;
		visibility: visible;
		max-block-size: none;
		display: block;
		font-size: 10pt;
	}

	body {
		padding: 0.5em;
		overflow: auto;
	}

	/* hide unnecessary elements */
	.no-print {
		display: none;
	}

	.is-printed-version {
		.modal-container,
		.modal,
		.modal-content-inner,
		.modal-two-content,
		.modal-two,
		.modal-two-dialog,
		.modal-two-dialog-container {
			@extend .reset4print;
		}

		.modal-content-inner::before,
		.modal-content-inner::after {
			content: none;
		}

		.app-root,
		.modal-overlay,
		.modal-two-backdrop,
		.modal-two-header,
		.modal-two-footer,
		.item-icon,
		.scroll-start-shadow,
		.scroll-end-shadow {
			@extend .no-print;
		}
	}

	/* display specific elements for print */
	.on-print {
		display: block;
	}

	/* avoid problematic page break */
	ul,
	ol {
		page-break-inside: avoid;
	}

	h1,
	h2,
	h3,
	caption {
		page-break-after: avoid;
	}

	.keep-color {
		/* stylelint-disable-next-line */
		print-color-adjust: exact;
	}

	.main {
		border: 0;
	}
}

.only-print {
	@include media('screen') {
		display: none;
	}
}
