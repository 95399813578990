.step-dots {
	&-item:not(:last-child) {
		margin-inline-end: 0.5em;
	}

	&-dot {
		inline-size: rem(8);
		block-size: rem(8);
		background-color: var(--interaction-weak);
		color: var(--text-norm);
		transition: background-color 0.25s easing(ease);

		&:focus,
		&:hover,
		&:active {
			background-color: var(--interaction-norm);
			color: var(--interaction-norm-contrast);
		}

		&[aria-selected='true'] {
			background-color: var(--primary);
			color: var(--text-invert);
		}
	}
}
