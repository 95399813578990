.apps-dropdown {
	&-button {
		@extend .interactive;

		border-radius: var(--border-radius-md);
		border: 1px solid transparent;
		margin-inline-end: rem(-4);
		inline-size: rem(36);
		block-size: rem(36);
		color: var(--text-weak);

		&:hover {
			color: var(--text-norm);
		}
	}

	.dropdown-content ul {
		display: grid;
		grid-template-columns: repeat(var(--apps-dropdown-repeat), 1fr);
		gap: var(--space-6);
		justify-items: center;
	}

	.apps-dropdown-app-name,
	.apps-dropdown-logo-wrapper {
		transition: 0.25s ease;
	}

	.apps-dropdown-link {
		&:hover {
			.apps-dropdown-app-name {
				color: var(--text-norm);
			}

			.apps-dropdown-logo-wrapper {
				background-color: var(--interaction-default-hover);
			}
		}

		&,
		&:hover,
		&:focus,
		&:active {
			&[aria-current] {
				pointer-events: auto;
				background-color: transparent;
			}
		}

		@supports not selector(:focus-visible:has(a, b)) {
			&:focus,
			&:focus-within {
				.apps-dropdown-logo-wrapper {
					border-color: var(--focus-outline);
					box-shadow: 0 0 0 #{$focus-ring-size} var(--focus-ring);
					color: var(--text-norm);

					&:hover {
						background-color: var(--interaction-default-hover);
					}
				}
			}
		}

		@supports selector(:focus-visible:has(a, b)) {
			&:focus-visible,
			&:has(*:focus-visible) {
				.apps-dropdown-logo-wrapper {
					border-color: var(--focus-outline);
					box-shadow: 0 0 0 #{$focus-ring-size} var(--focus-ring);
					color: var(--text-norm);

					&:hover {
						background-color: var(--interaction-default-hover);
					}
				}
			}
		}
	}
}
