.radio {
	@extend .sr-only;

	&-fakeradio {
		@extend .checkbox-fakecheck;

		border-radius: 50%;

		&::before {
			content: "";
			inline-size: em(10);
			block-size: em(10);
			margin: auto;
			border-radius: 50%;
			background: transparent;
			transform: scale(0);
			transition: 0.15s easing(ease-out-cubic),
				0.15s transform easing(ease-out-back);
		}
	}

	&:hover + &-fakeradio {
		border-color: var(--interaction-norm);
		background-color: var(
			--field-focus-background-color,
			var(--background-norm)
		);
	}

	@supports not selector(:focus-visible) {
		&:focus + &-fakeradio {
			border-color: var(--interaction-norm);
			box-shadow: 0 0 0 #{$focus-ring-size} var(--focus-ring);
			background-color: var(--field-focus-background-color);
			color: var(--field-focus-text-color);
		}

		&:focus:hover + &-fakeradio {
			border-color: var(--interaction-norm-hover);
		}
	}

	@supports selector(:focus-visible) {
		&:focus-visible + &-fakeradio {
			border-color: var(--interaction-norm);
			box-shadow: 0 0 0 #{$focus-ring-size} var(--focus-ring);
			background-color: var(--field-focus-background-color);
			color: var(--field-focus-text-color);
		}

		&:focus-visible:hover + &-fakeradio {
			border-color: var(--interaction-norm-hover);
		}
	}

	&[aria-invalid="true"] + &-fakeradio {
		border-color: var(--signal-danger);
		background-color: var(--field-background-color);
	}

	&[disabled] + &-fakeradio {
		border-color: var(--field-disabled);
		background-color: var(
			--field-disabled-background-color,
			var(--background-norm)
		);
	}

	/*
	 * Checked state
	 */
	&:checked + &-fakeradio {
		&::before {
			transform: scale(1);
			background-color: var(--interaction-norm);
		}
	}

	@supports not selector(:focus-visible) {
		&:checked:focus:hover + &-fakeradio {
			border-color: var(--interaction-norm-hover);

			&::before {
				background-color: var(--interaction-norm-hover);
			}
		}
	}

	@supports selector(:focus-visible) {
		&:checked:focus-visible:hover + &-fakeradio {
			border-color: var(--interaction-norm-hover);

			&::before {
				background-color: var(--interaction-norm-hover);
			}
		}
	}

	&:checked[aria-invalid="true"] + &-fakeradio::before {
		background-color: var(--signal-danger);
	}

	&:checked[disabled] + &-fakeradio::before {
		background-color: var(--field-disabled);
	}

	/*
	 * Modifiers
	 */
	&--ontop &-fakeradio {
		margin-block-start: 0;
	}
}
