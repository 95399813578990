.modal-two-content {
	margin-block: 0.5em 1em;
	margin-inline: var(--margin);

	& > *:first-child {
		margin-block-start: 0;
	}

	& > *:last-child {
		margin-block-end: 0;
	}
}
