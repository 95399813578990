@use 'sass:string';

@mixin gen-rotate($axis, $angle) {
	transform: #{'rotate' + string.to-upper-case($axis) }(#{$angle}deg); // migrate to CSS rotate prop later
}

.rotateX-180 {
	@include gen-rotate(x, 180);
}

.rotateY-180,
.mirror,
[dir='rtl'] .rtl\:mirror {
	@include gen-rotate(y, 180);
}

.rotateZ-45 {
	@include gen-rotate(z, 45);
}

.rotateZ-90 {
	@include gen-rotate(z, 90);
}

.rotateZ-270 {
	@include gen-rotate(z, 270);
}
