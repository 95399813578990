.placeholder,
::placeholder {
	color: var(--field-placeholder-color);
	font-style: normal;
	opacity: 1; // For Firefox -_-v
}

$field-container: rem(260) !default;

.field-container {
	inline-size: 100%;
	display: inline-block;
	position: relative;
}

.field {
	block-size: rem($default-height-fields);
	min-block-size: rem($default-height-fields); // To ensure minimum size when overriding block-size to 'auto'
	padding-inline: em(16);
	padding-block: em(6); // design want 34px height (actually 35 to avoid .5px)
	border-radius: var(--border-radius-md);
	border: 1px solid var(--field-norm);
	background-color: var(--field-background-color);
	color: var(--field-text-color);
	outline: none;
	filter: none; // fixes Firefox autofill color
	transition:
		0.15s easing(ease-out-quint),
		visibility 0s; // Disable visibility transition due to auto focus, e.g. login form

	@at-root {
		details & {
			block-size: auto; // crazy chrome bug in details (share drive modal)
		}
	}

	@include media('<=xsmall') {
		padding-inline: em(8);
	}

	@include media('<=small') {
		font-size: max(16px, em($base-font-size));
	}

	.is-drawer-app & {
		font-size: em($base-font-size);
	}

	@at-root {
		details & {
			block-size: auto;
		}
	}

	&.field--tiny,
	&.field--small,
	&.field--large {
		block-size: auto;
	}

	&:hover {
		border-color: var(--field-hover);
		background-color: var(--field-hover-background-color);
		color: var(--field-hover-text-color);
	}

	&:focus,
	&.focus,
	&:focus-within,
	&[aria-expanded='true']:not([aria-controls]) {
		border-color: var(--focus-outline);
		box-shadow: 0 0 0 #{$focus-ring-size} var(--focus-ring);
		background-color: var(--field-focus-background-color);
		color: var(--field-focus-text-color);
	}

	@supports selector(:focus-visible) {
		&:focus-visible {
			// might seem useless for inputs like text (they DO trigger focus-visible), but will be useful for "fake" select (selectV2) - also separated for old Safaris < 15.3
			border-color: var(--focus-outline);
			box-shadow: 0 0 0 #{$focus-ring-size} var(--focus-ring);
			background-color: var(--field-focus-background-color);
			color: var(--field-focus-text-color);
		}
	}

	&[disabled] {
		border-color: var(--field-disabled);
		background-color: var(--field-disabled-background-color);
		color: var(--field-disabled-text-color);
	}

	&[aria-invalid='true']:not(:focus),
	&[aria-invalid='true']:not(.ng-pristine) {
		border-color: var(--signal-danger);
	}

	&.is-valid:not(:focus) {
		border-color: var(--signal-success);
	}

	&.w-full {
		inline-size: 100%;
	}

	&.w-auto {
		inline-size: auto;
	}

	&[type='search'] {
		padding-inline-start: 2em;

		&::-webkit-search-cancel-button {
			display: none;
		}
	}

	&[type='number'] {
		padding-inline-end: 0.25em;
	}

	&.select {
		padding-inline-end: rem(6);

		&:focus-visible {
			&#{&} {
				outline: 0; // focus is provided by input fields
			}
		}

		.m-auto {
			display: flex;
			inline-size: 100%;
		}

		.mr-2 {
			flex: 1 1 0px;
			@extend .text-ellipsis;
		}
	}
}

// Fix autofill state
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
	// Trick to replace the background color
	box-shadow: 0 0 0 1000px var(--field-background-color) inset !important;
	-webkit-text-fill-color: var(--field-text-color) !important;
	caret-color: var(--field-text-color) !important;
}

// Fix datalist display, remove browser default caret
[list]::-webkit-calendar-picker-indicator {
	display: none !important; // had to use important otherwise not working -_-v
}

select.field {
	background: var(--select-background-color);
	background-size: rem(15);
	padding-inline: 1em rem(20);
	text-overflow: ellipsis;
	overflow: hidden;
	cursor: pointer;

	&::-ms-expand {
		display: none;
	}

	& > optgroup {
		background-color: var(--background-weak);
	}
}

/* hotfix for Chrome */
select.text-center {
	text-align-last: center;
}

textarea.field,
.textarea.field {
	block-size: auto;
}

/* errors */
.error-zone {
	transition:
		transform 0.15s linear,
		max-block-size 0.15s linear;
	transform: scaleY(1);
	transform-origin: top center;

	&:empty {
		transform: scaleY(0);
	}
}

// general rule: if focus is on the field => don't display error yet
.field-icon-container:focus-within + .error-zone,
.field:focus + .error-zone {
	transform: scaleY(0);
	max-block-size: 0;
}

/* sizes/other modifiers */
.field--tiny {
	padding: em(6); // useful for address group
}

.field--small {
	padding-block: em(2); // design want 26px height (actually 27 to avoid .5px)
	padding-inline: em(16);
}

.field--large {
	padding-block: em(10); // design want 42px height (actually 43 to avoid .5px)
	padding-inline: em(16);
}

.field--highlight {
	border-color: var(--primary);
	border-width: 2px;
}

/* field with icon */
.field-icon-container {
	@extend .field; // added focus-within inside on field for this fake-field :)

	padding: 0;

	> .field {
		border: 0;
		inline-size: calc(100% - 1.5em);
	}

	&,
	&-empty {
		// for drive, to keep the same height
		min-block-size: calc(#{rem($default-height-fields)} + 2px);
	}

	&:focus-within {
		> .field {
			background-color: transparent;
			box-shadow: none;
			outline: 0;
		}
	}
}

.dropdown-content .select--inline-caret-option {
	display: none;
}

.field-icon-container--invalid:not(:focus-within) {
	border-color: var(--signal-danger);

	> .field {
		border-color: var(--signal-danger);
	}
}

/*
 * REMOVE THIS IF POSSIBLE
 */

/* @protonmail.com field */
.field--username,
.sign-layout-container .field--username.field--username {
	padding-inline-end: var(--padding-right-field-above, 9em);
}

/* special stupid case for signup iframe challenge... not using same styles for the moment */
.sign-layout-container {
	.field--username {
		padding-block: em(10);
		padding-inline: em(16); // design want 42px height (actually 41 to avoid .5px)
	}
}

/*
 * Specific for input fields
 * TO_CHECK if removable
 */
.right-icon,
.right-text,
.left-icon {
	inset-block-start: 0;
	block-size: #{rem($default-height-fields)} !important; // height of field, need to override `.icon16p`
	align-content: center;
}

.right-icon {
	inset-inline-end: 0.5em;
}

.right-text {
	inset-inline-end: 1em;

	&-inner {
		padding-block-end: rem(2);
	}
}

.left-icon {
	inset-inline-start: 0.5em;
}
