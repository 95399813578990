.toolbar {
	--padding-toolbar-button: #{em(9)};
	--padding-toolbar-button-dropdown: #{em(9)};
	--height-toolbar-button: #{rem(36)};

	position: relative;
	z-index: 1;
	background-color: var(--toolbar-background-color);
	color: var(--toolbar-text-color);

	&:not(#{&}--in-container) {
		border-block-end: 1px solid var(--toolbar-border-bottom-color);
	}

	// only set border radius when drawer is shown
	// same naming as .main--bordered
	&--bordered {
		border-start-end-radius: var(--border-radius-md);
	}

	&--heavy {
		block-size: rem(45);
	}

	&--in-container {
		block-size: 100%;
	}

	&--no-bg {
		background-color: transparent;
	}

	&-button {
		@extend .interactive;

		position: relative;
		display: flex;
		flex-shrink: 0;
		align-items: center;
		padding-inline: var(--padding-toolbar-button);
		border-radius: var(--border-radius-md);
		border: 1px solid transparent;
		block-size: var(--height-toolbar-button);

		&--small {
			--height-toolbar-button: #{rem(28)};
		}

		&--small-icon {
			--padding-toolbar-button: #{em(5)};
			--padding-toolbar-button-dropdown: #{em(5)};
		}

		// Increase click area to toolbar height
		&::before {
			content: '';
			position: absolute;
			inset: em(-6);
		}

		&--dropdown {
			padding-inline: var(--padding-toolbar-button-dropdown);

			&-more-selections {
				--padding-toolbar-button-dropdown: 0;
				--height-toolbar-button: rem(20) !important;
			}
		}
	}

	&-separator {
		inline-size: 1px;
		background: var(--toolbar-separator-color);
	}

	.vr.vr {
		block-size: rem(24);
		align-self: center;
		border-inline-end-color: var(--toolbar-border-bottom-color);
	}

	button[aria-disabled='true'],
	.toolbar-button[aria-disabled='true'] {
		opacity: 0.5;
		pointer-events: none;
	}

	// new select
	.select {
		@extend .interactive;

		border-color: transparent;
		background-color: unset;
		color: currentcolor;
		block-size: auto;
		margin-inline: 0.25em;
		border-radius: var(--border-radius-md);
	}

	// select all
	.select-all {
		&-wrapper {
			block-size: rem(28);

			&:hover {
				background: var(--interaction-default-hover);

				button {
					background-color: transparent;
				}
			}

			&:has(:hover, :focus, [aria-expanded='true']) {
				background: var(--interaction-default-hover);

				button {
					background-color: transparent;
				}
			}
		}

		.checkbox-fakecheck {
			margin-inline-end: 0;
		}
	}

	@include media('<=small') {
		border-radius: 0;
	}

	&-page-number-dropdown {
		&#{&} {
			min-inline-size: em(64);
			padding: 0;
			display: block;
			text-align: center;
		}

		span {
			margin-inline: em(1);
		}
	}
}
