@import '~@proton/styles/scss/lib';

// Increase the challenge width on the iframe to show the input outline correctly.
// These values (--space-2) go with the bodyClassName on the challenge
.challenge-width-increase {
	margin-inline-start: calc(var(--space-2) * -1);
	inline-size: calc(100% + var(--space-4));
}

.email-input-field {
	&#{&} {
		padding-inline: var(--space-4);
		font-size: em($base-font-size);
	}

	// Rely on parent frame size through a flag classname
	.xsmall & {
		padding-inline: em(8);
	}

	.xsmall &,
	.small & {
		font-size: max(16px, em($base-font-size));
	}
}
