.topnav-link {
	&--blackfriday::before {
		content: '';
		position: absolute;
		inline-size: rem(7);
		block-size: rem(7);
		background: var(--signal-danger);
		border-radius: 50%;
		inset-inline-start: calc(#{rem(17.5)} + #{rem(15)});
		inset-block-start: calc(50% - #{rem(14)});
	}
}

.topnav-list > li:not(:last-child) {
	margin-inline-end: 0.25em;

	/* Decrease margins (was 1em) */
	@include media('<=#{em(1500, 16)}') {
		margin-inline-end: 1px;
	}
}

.topnav-icon {
	margin-inline-end: 0.5em;
}

.topnav-vr {
	@include media('<=large') {
		display: none;
	}
}

// code to collapse all elements except upgrade button (topnav-listItem--noCollapse)
@mixin collapse-all-four-except-upgrade {
	.topnav-list--four-elements {
		.topnav-listItem:not(.topnav-listItem--noCollapse) {
			// we collapse text as general case
			.navigation-title {
				// equivalent of .sr-only, but impossible to extend in a breakpoint
				border: 0;
				clip: rect(0 0 0 0);
				block-size: 1px;
				margin: -1px;
				overflow: hidden;
				padding: 0;
				position: absolute;
				inline-size: 1px;
			}

			.topnav-icon {
				margin-inline-end: 0;

				&.expand-caret {
					margin-inline-start: 0.25em;
				}
			}
		}
	}
}

// code to collapse all elements with NO exception
@mixin collapse-all-four-no-exception {
	.topnav-list--four-elements {
		.topnav-link {
			padding-inline: rem(5);
		}

		.topnav-listItem:not(.topnav-listItem--noCollapse) {
			.navigation-title {
				// collapse for ALL without any exception
				// equivalent of .sr-only, but impossible to extend in a breakpoint
				border: 0;
				clip: rect(0 0 0 0);
				block-size: 1px;
				margin: -1px;
				overflow: hidden;
				padding: 0;
				position: absolute;
				inline-size: 1px;
			}
		}

		.topnav-icon {
			margin-inline-end: 0;
		}

		.topnav-link--blackfriday::before {
			inset-inline-start: calc(#{rem(5)} + #{rem(15)});
		}

		.topnav-link--notification {
			.notification-dot {
				inset-inline-end: 0.1em;
				inset-block-start: 0.1em;
			}
		}
	}
}

@mixin collapse-all-items-no-exception {
	.topnav-link {
		// Specificity -_-v
		&#{&} {
			padding-inline: rem(5);
		}
	}

	.topnav-listItem:not(.topnav-listItem--noCollapse) {
		.navigation-title {
			// collapse for ALL without any exception
			// equivalent of .sr-only, but impossible to extend in a breakpoint
			border: 0;
			clip: rect(0 0 0 0);
			block-size: 1px;
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
			inline-size: 1px;
		}
	}

	.topnav-icon {
		&#{&} {
			// Specificity -_-v
			margin-inline-end: 0;
		}
	}

	.topnav-link--blackfriday::before {
		inset-inline-start: calc(#{rem(5)} + #{rem(15)});
	}
}

@include media('<=xlarge') {
	@include collapse-all-four-except-upgrade;
}

// special fix for Hungarian looooooooong text
[lang='hu'] {
	@include media('<=#{em(1650, 16)}') {
		@include collapse-all-four-except-upgrade;
	}
	@include media('<=#{em(1050, 16)}') {
		@include collapse-all-four-no-exception;
	}
}

@include media('<=#{em(940, 16)}') {
	@include collapse-all-four-no-exception;
}

@include media('<=#{em(800, 16)}') {
	@include collapse-all-items-no-exception;
}

@include media('<=small') {
	.topnav-icon {
		// Specificity -_-v
		&#{&} {
			inline-size: rem(24);
			block-size: rem(19); // to align with user drop down
			margin-inline-end: 0;
		}
	}

	.topnav-listItem:not(.topnav-listItem--noCollapse) {
		.navigation-title {
			// collapse for ALL without any exception
			// equivalent of .sr-only, but impossible to extend in a breakpoint
			border: 0;
			clip: rect(0 0 0 0);
			block-size: 1px;
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
			inline-size: 1px;
		}
	}

	.topnav-list--four-elements .topnav-link {
		padding-inline: rem(10);
	}

	.topnav-list--four-elements .topnav-link--blackfriday::before,
	.topnav-link--blackfriday::before {
		inset-inline-start: calc(#{rem(10)} + #{rem(22)});
		inset-block-start: calc(50% - #{rem(18)});
	}

	.topnav-link--notification {
		&#{&} {
			.notification-dot {
				inset-inline-end: 0.15em;
				inset-block-start: 0.15em;
			}
		}
	}
}

@include media('<=xsmall') {
	.topnav-list--four-elements .topnav-link,
	.topnav-link {
		padding-inline: rem(2);
	}

	.topnav-list--four-elements .topnav-link--blackfriday::before,
	.topnav-link--blackfriday::before {
		inset-inline-start: calc(#{rem(5)} + #{rem(22)});
	}
}
