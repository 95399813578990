@import '~@proton/styles/scss/lib';

.slider-mark {
	inline-size: 1px;
	block-size: rem(12);
	background: var(--text-norm);
	position: absolute;
	inset-block-start: 100%;

	&-min {
		inset-inline-start: 0;
	}

	&-max {
		inset-inline-end: 0;
	}
}

.slider-mark-label {
	inset-block-start: 100%;
	position: absolute;
	transform: translateX(calc(-50% + 0.5px));

	[dir='rtl'] & {
		transform: translateX(calc(50% - 0.5px));
	}
}
