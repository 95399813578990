@keyframes anime-move-light {
	0% {
		opacity: 0;
		cx: 0;
		cy: -60;
	}

	50% {
		opacity: 1;
		cx: 50;
	}

	100% {
		opacity: 0;
		cx: 20;
		cy: 112;
	}
}

.proton-loader-light {
	animation: 2s ease-in-out infinite anime-move-light;
}
