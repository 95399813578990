@use 'sass:map';

.file-preview {
	position: fixed;
	inset: 0;
	z-index: map.get($z-indexes, 'previewer');
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	background-color: var(--file-preview-background-color, var(--background-norm));
	color: var(--file-preview-text-color);

	&-container {
		position: relative;
		display: flex;
		inline-size: 100%;
		overflow: auto;
		flex-flow: column wrap;
		flex: 1 1 0;
	}

	&-filename {
		max-inline-size: calc(50% - 5em);
	}

	&-text {
		inline-size: 100%;
		max-inline-size: rem(800);
		padding: 2rem;
		margin-inline: auto;
		margin-block: var(--space-1) var(--space-8); // So the highlight in case of textarea is fully visible on the top too.
		background-color: whitesmoke;
		color: black;
		flex: 1 1 auto;
		word-break: break-word;
		white-space: pre-wrap;
	}

	&-image {
		position: absolute;
		inset-block-start: 0;
		inset-inline-start: 0;
		margin: auto;
		max-inline-size: none;
		object-fit: contain;

		&-out {
			animation: anime-image-preview-out 0.2s ease-in-out forwards;
		}
	}

	&-loading {
		position: absolute;
		inset-block-end: 0;
		block-size: 2.5em;
	}
}

@keyframes anime-image-preview-out {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}
