@import '~@proton/styles/scss/lib';

.right-summary {
	--summary-gradient-border-color: 218 211 255;
	--summary-gradient-background-color-1: rgb(109 74 255 / 0.037);
	--summary-gradient-background-color-2: rgb(70 26 255 / 0.01);

	background: var(--background-norm);

	&--gradient {
		border: 1px solid var(--border-norm);
		background: linear-gradient(
				81.4deg,
				var(--summary-gradient-background-color-1) 0%,
				var(--summary-gradient-background-color-2) 100%
			),
			var(--background-norm);
	}

	&--gradient-border {
		border: 1px solid transparent;
		background:
			linear-gradient(var(--background-norm), var(--background-norm)) padding-box,
			linear-gradient(
					to bottom,
					rgb(var(--summary-gradient-border-color)),
					rgb(var(--summary-gradient-border-color) / 0.25)
				)
				border-box;
	}

	&--border {
		border: 1px solid var(--border-norm);
	}
}
