@import '~@proton/styles/scss/lib';

.modal-two-footer {
	display: flex;
	margin-block: 1em calc(var(--margin) - 0.5em);
	margin-inline: var(--margin);
	justify-content: space-between;
	flex-wrap: wrap-reverse;
	flex-shrink: 0;

	@include media('<=xsmall') {
		flex-direction: column-reverse;
		align-items: stretch;
	}

	&--full {
		@include media('>xsmall') {
			justify-content: flex-end;

			& > *:not(:first-child) {
				margin-inline-start: 0.5em;
			}
		}
	}

	& > * {
		// When children are stacked
		margin-block-end: 0.5em;
	}
}
