@use '~inter-ui/default' with (
	$inter-font-path: '~inter-ui/web'
);

@use '~inter-ui/variable' with (
	$inter-font-path: '~inter-ui/variable'
);

/*
Only include font faces that we need.
Only used by outdated browsers/os
Minimises the number of font requests made which is then duplicated by the challenge script. This can be removed when the challenge script no longer makes style requests.
*/
@include default.weight-400-normal;
@include default.weight-500-normal;
@include default.weight-600-normal;
@include default.weight-700-normal;

@include variable.all;

// --------------------------------------------------------------------------------------

/*
OpenDyslexic font face definition for font face setting
*/
$opendyslexic-font-path: '~@proton/styles/assets/fonts';

@font-face {
	font-family: OpenDyslexic;
	font-style: normal;
	font-weight: normal;
	font-display: swap;
	src:
		local('OpenDyslexic'),
		url('#{$opendyslexic-font-path}/OpenDyslexic-Regular.woff2') format('woff2'),
		url('#{$opendyslexic-font-path}/OpenDyslexic-Regular.woff') format('woff');
}

@font-face {
	font-family: OpenDyslexic;
	font-style: italic;
	font-weight: normal;
	font-display: swap;
	src:
		local('OpenDyslexic'),
		url('#{$opendyslexic-font-path}/OpenDyslexic-Italic.woff2') format('woff2'),
		url('#{$opendyslexic-font-path}/OpenDyslexic-Italic.woff') format('woff');
}

@font-face {
	font-family: OpenDyslexic;
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src:
		local('OpenDyslexic'),
		url('#{$opendyslexic-font-path}/OpenDyslexic-Bold.woff2') format('woff2'),
		url('#{$opendyslexic-font-path}/OpenDyslexic-Bold.woff') format('woff');
}

@font-face {
	font-family: OpenDyslexic;
	font-style: italic;
	font-weight: 600;
	font-display: swap;
	src:
		local('OpenDyslexic'),
		url('#{$opendyslexic-font-path}/OpenDyslexic-Bold-Italic.woff2') format('woff2'),
		url('#{$opendyslexic-font-path}/OpenDyslexic-Bold-Italic.woff') format('woff');
}

// --------------------------------------------------------------------------------------

:root {
	--font-weight-normal: 400;
	--font-weight-semibold: 600;
	--font-weight-bold: 700;
}

$fallback-fonts: system-ui, sans-serif;

html,
.apply-font {
	// Set fallback font stack as default
	font-family: var(--setting-font-family, var(--optional-font-family, #{$fallback-fonts}));
	font-synthesis: none;
	font-weight: var(--font-weight-normal);
	font-style: normal;

	/* stylelint-disable */
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
	/* stylelint-enable */

	// If the browser supports `@supports`, set Inter static if it doesn't support variable fonts
	@supports not (font-variation-settings: normal) {
		font-family: var(--setting-font-family, var(--optional-font-family, 'Inter', #{$fallback-fonts}));
	}

	// If the browser supports `@supports`, set Inter variable if it supports variable fonts
	@supports (font-variation-settings: normal) {
		font-family: var(--setting-font-family, var(--optional-font-family, 'InterVariable', #{$fallback-fonts}));
	}
}
