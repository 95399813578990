@import '~@proton/styles/scss/lib';

.password-strength-indicator {
	--strength-color: var(--signal-danger);
	--bar-color: var(--border-norm);

	transition: opacity 150ms ease;
	font-size: rem($base-font-size);

	&--vulnerable &-bars span:nth-child(1),
	&--weak &-bars span:nth-child(1),
	&--weak &-bars span:nth-child(2),
	&--strong &-bars span:nth-child(1),
	&--strong &-bars span:nth-child(2),
	&--strong &-bars span:nth-child(3) {
		--bar-color: var(--strength-color);
	}

	&--weak {
		--strength-color: var(--signal-warning);
	}

	&--strong {
		--strength-color: var(--signal-success);
	}

	&-bars {
		span {
			--bar-transparency: 0%;

			background: linear-gradient(
				to right,
				var(--bar-color),
				color-mix(in srgb, var(--bar-color), transparent var(--bar-transparency))
			);
			transition: background 150ms ease;
			block-size: rem(2);

			&:nth-child(2) {
				--bar-transparency: 30%;

				transition-duration: 300ms;
			}

			&:nth-child(3) {
				--bar-transparency: 50%;

				transition-duration: 450ms;
			}
		}
	}

	&-accent {
		--bar-color: var(--strength-color);
	}

	&-value {
		color: var(--strength-color);
	}

	&-checkmark {
		transition: all 150ms easing(ease-out-quad);
	}
}
