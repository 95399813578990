@import '~@proton/styles/scss/lib';

.avatar {
	inline-size: rem($conversation-icon-size);
	block-size: rem($conversation-icon-size);
	font-size: 0.75rem; // 12
	background-color: var(--primary);
	color: var(--primary-contrast);

	&--weak {
		background-color: var(--background-strong);
		color: var(--text-norm);
	}
}
