.color-selector {
	&-container {
		--color-item-size: #{em(44)};
		--color-grid-items: 5;

		display: grid;
		gap: em(8);
		justify-content: center;
		grid-template-columns: repeat(var(--color-grid-items), var(--color-item-size));

		&-inline {
			justify-content: space-between;
		}
	}

	&-input[type='radio'] {
		position: absolute;
		opacity: 0;
	}

	&-item {
		position: relative;
		display: flex;
		block-size: var(--color-item-size);
		inline-size: var(--color-item-size);
		overflow: hidden;
		border-radius: 50%;
		background-color: var(--color-selector-base);
		cursor: pointer;
		transition: 0.15s easing(ease-out-quint);

		&::before {
			content: '';
			position: absolute;
			inset: 0;
			border-radius: 50%;
			z-index: 1;
			border: em(3) solid transparent;
			transition: inherit;
		}

		&::after {
			content: '';
			display: block;
			inline-size: em(12);
			block-size: em(12);
			border-radius: 50%;
			margin: auto;
			background-color: transparent;
			transition: inherit;
		}

		&:hover,
		&:focus-within {
			&::before {
				border-color: var(--color-selector-strong);
			}
		}

		&.is-selected {
			&::before {
				border-color: var(--color-selector-intense);
			}

			&::after {
				background-color: white;
			}
		}
	}
}
