@use 'sass:math';

$height-wizard: rem(6) !default;
$size-current-pin: rem(16) !default;

.wizard {
	block-size: $height-wizard;
	display: flex;

	&-container {
		padding-inline-start: rem(2); // "optical" alignment, difficult to align text and circle ^^
	}

	&-marker {
		display: flex;
		inline-size: $size-current-pin;
		block-size: $size-current-pin;
		margin-inline-start: -1px; // trick for high zoom level ^^
		border-radius: 50%;
		position: relative;
		inset-block-start: -#{$height-wizard - rem(1)};
		background-image: radial-gradient(
			circle,
			var(--primary) 0,
			var(--primary) #{math.div($height-wizard, 2)},
			transparent #{math.div($height-wizard, 2)}
		);
	}

	&-marker-icon {
		&#{&} {
			fill: var(--primary-contrast);
		}
	}

	&-item {
		background: var(--background-strong);
		position: relative;

		&:not(:last-child) {
			@extend .flex-1;
		}

		// positionned first element always with a aria-current/is-complete
		&:first-child {
			background: none;
			background-image: linear-gradient(
				to left,
				var(--background-strong) 0,
				var(--background-strong) calc(100% - #{math.div($height-wizard, 2)}),
				transparent calc(100% - #{math.div($height-wizard, 2)})
			);
		}

		&:last-child {
			background: none;
			background-image: linear-gradient(
				to right,
				var(--background-strong) 0,
				var(--background-strong) 50%,
				transparent 50%
			);

			&.is-complete::after {
				content: none;
			}
		}

		&.is-complete {
			.wizard-marker {
				background-color: var(--primary);
				position: relative;
				inset-block-start: -#{$height-wizard - rem(1)};
			}
		}

		&[aria-current='step'] {
			.wizard-marker {
				background-image: radial-gradient(
					circle,
					var(--primary-contrast) 0,
					var(--primary-contrast) #{math.div($height-wizard, 2)},
					var(--primary) #{math.div($height-wizard, 2)}
				);
			}
		}

		&.is-complete::before {
			content: '';
			background: var(--primary);
		}

		&.is-complete::after {
			content: '';
			position: relative;
			display: block;
			inline-size: 100%;
			block-size: $height-wizard;
			inset-block-start: -$size-current-pin;
			background-color: var(--primary);
		}
	}

	&-container--no-text-displayed &-item[aria-current='step'] &-item-inner {
		@extend .invisible;
	}

	&-label {
		flex: 1;
		text-align: center;

		&:first-child {
			text-align: start;
		}

		&:last-child {
			text-align: end;
		}

		&.is-complete {
			opacity: 0.5;
		}

		&[aria-current='step'] {
			font-size: 1em;
			font-weight: var(--font-weight-semibold);
		}
	}
}
