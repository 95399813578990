.input-button.button {
	--button-default-border-color: var(--field-norm);
	--button-default-background-color: var(--background-norm);
	--button-default-text-color: var(--text-norm);
	--button-hover-border-color: var(--field-hover);
	--button-hover-background-color: var(--interaction-default-hover);
	--button-hover-text-color: var(--text-norm);
	--button-active-border-color: var(--interaction-norm);
	--button-active-background-color: var(--interaction-default-active);
	--button-active-text-color: var(--text-norm);

	inline-size: em(36);
	padding: em(7); // Same as inputs

	// quickfix for DayCheckbox
	&.px-0 {
		padding-inline: 0;
	}
}

.input-button-input {
	&:focus + .input-button {
		--button-default-background-color: var(--interaction-default-hover);
		--button-default-text-color: var(--text-norm);
	}

	&:checked + .input-button {
		--button-default-border-color: var(--interaction-norm);
		--button-default-background-color: var(--interaction-norm);
		--button-default-text-color: var(--interaction-norm-contrast);
		--button-hover-border-color: var(--interaction-norm-hover);
		--button-hover-background-color: var(--interaction-norm-hover);
		--button-hover-text-color: var(--interaction-norm-contrast);
		--button-active-border-color: var(--interaction-norm-active);
		--button-active-background-color: var(--interaction-norm-active);
		--button-active-text-color: var(--interaction-norm-contrast);
	}

	&:checked:focus + .input-button {
		--button-default-background-color: var(--interaction-norm-hover);
		--button-default-text-color: var(--interaction-norm-contrast);
	}
}
