$scrollshadow-height: rem(5) !default;

.scrollshadow {
	&-sticky {
		position: sticky;
		display: block;
		block-size: $scrollshadow-height;

		&--top {
			inset-block-start: 0;
			background-image: radial-gradient(farthest-side at 50% 0, var(--background-strong), transparent);
		}

		&--bottom {
			inset-block-end: 0;
			background-image: radial-gradient(farthest-side at 50% 100%, var(--background-strong), transparent);
		}
	}

	&-static {
		position: absolute;
		display: block;
		inset-inline: 0;
		block-size: $scrollshadow-height;
		background: var(--background-norm);

		&--top {
			inset-block-start: 0;
		}

		&--bottom {
			inset-block-end: 0;
		}
	}
}
