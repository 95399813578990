.container-section-sticky {
	padding-block: 0;
	padding-inline: 2em;
	inline-size: 100%;
	max-inline-size: 120ch; // avoid too long lines
	margin-inline: auto auto;

	@include media('>xlarge') {
		max-inline-size: 75vw;
	}

	&--full-width {
		max-inline-size: none;
	}

	&-section {
		margin-block-end: rem(80);

		&--single-calendar-section {
			// temporary margin by hand until better global solution found
			margin-block-start: em(54);

			@include media('<=small') {
				// full alignment not possible
				margin-block-start: 0;
			}
		}
	}
}

/* trick for fixing anchor links */
.header-height-anchor {
	position: absolute;
	inset-block-start: -#{rem(100)};
}

// not sticky on mobile
@include media('<=small') {
	.container-section-sticky-section {
		margin-block-end: rem(80);
	}

	.header-height-anchor {
		position: static;
		inset-block-start: 0;
	}
}
