.context-bar {
	background: var(--background-strong);

	@include media('>medium') {
		inline-size: $context-bar-width;
		min-inline-size: rem($context-bar-min-width);
		max-inline-size: $context-bar-max-width;
		border-inline-start: 1px solid var(--border-norm);
	}

	@include media('<=medium') {
		border-block-start: 1px solid var(--border-norm);
	}

	&-inner {
		position: sticky;
		inset-block-start: 0;
		padding: 2em;
	}
}
