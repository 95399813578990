.textarea {
	min-block-size: rem(36);
	padding-block: em(7); // 36px height with wrapper borders, same as buttons
	padding-inline: em(16);
	border: 1px solid var(--field-norm);
	border-radius: var(--border-radius-md);
	background-color: var(--field-background-color);
	color: var(--field-text-color);
	outline: none;
	filter: none; // fixes Firefox autofill color
	transition: 0.15s easing(ease-out-quint), block-size 0s;

	@include media('<=xsmall') {
		padding-inline: em(8);
	}

	@include media('<=small') {
		font-size: max(16px, em($base-font-size));
	}

	.is-drawer-app & {
		font-size: em($base-font-size);
	}

	&:hover,
	&.hover {
		border-color: var(--field-hover);
		background-color: var(--field-hover-background-color);
		color: var(--field-hover-text-color);
	}

	&:focus,
	&.focus {
		border-color: var(--focus-outline);
		box-shadow: 0 0 0 #{$focus-ring-size} var(--focus-ring);
		background-color: var(--field-focus-background-color);
		color: var(--field-focus-text-color);
	}

	&.error {
		border-color: var(--signal-danger);
	}

	&.disabled {
		border-color: var(--field-disabled);
		background-color: var(--field-disabled-background-color);
		color: var(--field-disabled-text-color);
		pointer-events: none;

		&::placeholder {
			color: inherit;
		}
	}

	&:-moz-ui-invalid {
		// fixes some Firefox issues with HTML5 validation
		box-shadow: none;
	}

	&.unstyled {
		&#{&} {
			border-color: transparent;
			border-radius: 0;
			box-shadow: none;
			background-color: transparent;
			transition: none;
		}
	}
}
