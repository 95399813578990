$alert-block-border-width: 3px !default;

[class*='alert-block'] {
	padding-block: 0.2em;
	padding-inline: 1.2em;
	color: var(--text-norm);
	border-inline-start-width: $alert-block-border-width;
	border-inline-start-style: solid;
	overflow-wrap: break-word;
}

@each $color in (info, danger, warning, success) {
	$class: '';
	@if $color != 'info' {
		$class: '--' + $color;
	}

	.alert-block#{$class} {
		border-color: var(--signal-#{$color});
	}
}
