.multi-select {
	&-container {
		border-radius: var(--border-radius-md);
		border: 1px solid var(--field-norm);
		background-color: var(--field-background-color);
		transition: box-shadow 0.15s easing(ease-out-quint); // Use the same transition as input fields, but only for box-shadow (outline)

		&:focus,
		&:focus-within,
		&:focus-visible {
			border-color: var(--focus-outline);
			box-shadow: 0 0 0 #{$focus-ring-size} var(--focus-ring);
			background-color: var(--field-focus-background-color);
		}
	}
}
