@import '~@proton/styles/scss/lib';

.card-plan {
	--card-box-shadow-color1: rgb(205 79 232 / 0.28);
	--card-box-shadow-color2: rgb(108 73 254 / 0.3);
	--card-box-shadow-color3: rgb(246 198 146 / 0.3);
	--card-box-shadow-color4: rgb(172 154 246 / 0.3);
	--card-background: var(--background-norm); // can't use opacity because some issues with card borders
	--card-transition: background-color 0.2s ease, border 0.2s ease, opacity 0.2s ease, color 0.2s ease,
		box-shadow 0.4s ease, transform 0.4s ease;

	background-color: var(--card-background);
	transition: var(--card-transition);
	outline: none;

	&-highlight {
		&#{&} {
			background-color: var(--background-weak);
			color: var(--primary);
			transition: var(--card-transition);

			&--selected {
				background-color: var(--primary);
				color: var(--text-invert);

				@at-root .ui-prominent & {
					color: var(--text-norm);
				}
			}
		}
	}

	&-price {
		font-size: rem(32);
	}

	&-selected-indicator {
		svg {
			animation: 0.25s easing(ease-out-back) both anime-selected-indicator-appear;
			transition-delay: 0.1s;

			@keyframes anime-selected-indicator-appear {
				from {
					opacity: 0;
					transform: scale(0);
				}

				to {
					opacity: 1;
					transform: scale(1);
				}
			}
		}
	}

	&::after,
	&::before {
		content: '';
		border-radius: inherit;
		position: absolute;
		z-index: -1;
		inset: 0;
		pointer-events: none;
	}

	&::before {
		inset: -1px;
		border: var(--primary) solid 2px;
		opacity: 0;
		transition: var(--card-transition);
	}

	&[aria-pressed='true'] {
		box-shadow:
			-4px 4px 5px 2px var(--card-box-shadow-color1),
			6px 3px 7px 3px var(--card-box-shadow-color2),
			-5px -3px 6px 3px var(--card-box-shadow-color3),
			0 0 7px 3px var(--card-box-shadow-color4);

		&::before {
			opacity: 1;
		}
	}

	&:focus-visible {
		outline: none;

		&::after {
			border: 1px solid var(--focus-outline);
			box-shadow: 0 0 0 #{$focus-ring-size} var(--focus-ring);
			z-index: 0;
		}
	}

	&[type='button']:hover {
		background-color: var(--interaction-default-hover);
	}

	&:hover &-premium {
		background-color: var(--background-norm);
	}
}

.plan-card-selector-container {
	display: grid;
	justify-content: center;
	grid-template-columns: 1fr;
	gap: var(--space-4);

	&[data-plan-count='2'] {
		@include media('<=medium') {
			.pricing-box-content-cycle--highlighted {
				order: -1;
			}
		}

		@include media('>=small') {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	&[data-plan-count='3'] {
		@include media('<=medium') {
			.pricing-box-content-cycle--highlighted {
				order: -1;
			}
		}

		@include media('>=medium') {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	&[data-plan-count='4'] {
		@include media('<large') {
			.pricing-box-content-cycle {
				@for $i from 1 through 4 {
					&:nth-child(#{$i}) {
						order: #{$i};
					}
				}
			}

			.pricing-box-content-cycle--highlighted.pricing-box-content-cycle--highlighted {
				order: 1;
			}
		}

		@include media('>=small') {
			max-inline-size: fit-content;
			grid-template-columns: repeat(2, 1fr);
			gap: var(--space-6);
		}

		@include media('>=large') {
			max-inline-size: none;
			grid-template-columns: repeat(4, 1fr);
			gap: var(--space-4);
		}
	}
}
