/**
 * reduced motion MQ: for vestibular disorders
 */
@mixin reduce-motion {
	// keep-motion is for some SUPER particular cases where the animation must be kept, ie
	// - it brings sense to the action
	// - it's triggered by the user
	// - it's super small and played less than 3s
	// doc: https://www.w3.org/WAI/GL/wiki/Animation_caused_by_user_interaction
	// example, reload spinner on folders in Mail side bar

	*:not(.keep-motion),
	*:not(.keep-motion)::before,
	*:not(.keep-motion)::after {
		// Using values greater than 0 to have animation events still triggering
		animation-delay: 0.01ms !important;
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;

		// This magic value is needed because of https://bugs.webkit.org/show_bug.cgi?id=242510
		// which affects visibility changes on Safari (iOS + MacOS) when reduce motion is
		// enabled in accessibility settings. Even though transitions are not involved.
		// This needs to be set to 0 to prevent lag. In general in all browsers, but particularly
		// in safari when changing theme.
		// Warning: This breaks transition events, which need to be handled in JS.
		transition-delay: 0s !important;
		transition-duration: 0s !important;
	}
}

@include media('reduced-motion') {
	@include reduce-motion;
}

.feature-animations-off {
	@include reduce-motion;
}
