@use "sass:map";
@import '~@proton/styles/scss/lib';

.modal-two-backdrop {
	position: fixed;
	z-index: map.get($z-indexes, 'modals');
	inset: 0;
	background: var(--backdrop-norm);
	animation: 0.1s ease-out forwards;

	&--out {
		animation-name: anime-modal-two-backdrop-out;
	}

	&--in {
		animation-name: anime-modal-two-backdrop-in;
	}

	@keyframes anime-modal-two-backdrop-in {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	@keyframes anime-modal-two-backdrop-out {
		from {
			opacity: 1;
		}

		to {
			opacity: 0;
		}
	}
}
