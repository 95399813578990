@use 'sass:math';

html {
	/* Introduce `--setting-font-size` to make the font size setting possible. */

	/* chosen size * browser default size / app default size * 100% / browser default size */
	font-size: calc(var(--setting-font-size) * #{math.div(16, 14) * math.div(100%, 16)});
}

body {
	color: var(--text-norm);
	line-height: $base-line-height;
	font-size: em($base-font-size, $root-default-font-size);
}

@each $tag, $size in $title-sizes {
	#{$tag},
	.#{$tag} {
		display: block;
		margin: 0;
		font-size: em($size);
		font-weight: var(--font-weight-normal);
	}
}

/* Hyphenation: Thou shalt not pass (Moïse or Gandalf, don’t remember) */
@if $hyphenation == true {
	textarea,
	table,
	td,
	th,
	code,
	pre,
	samp,
	div,
	p {
		word-break: break-word;

		@supports (-webkit-hyphens: auto) or (hyphens: auto) {
			word-break: normal;
			@include vendor-prefix(hyphens, auto, webkit);
		}
	}
}
