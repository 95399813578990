.button-account {
	&-container {
		border: 1px solid var(--border-norm); // inconsistent with borders...
		max-block-size: 50vh;

		@include media('<=small') {
			max-block-size: none;
		}

		&-inner {
			border-inline-end: 1px solid var(--border-norm); // inconsistent with borders...
		}
	}
}
